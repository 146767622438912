import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import "../components/style.scss";
import "../components/columns.scss";
import "../components/formulario.scss";
import Helmet from "../components/helmet";
import Navbar from "../components/navbar";
import Footer from "../components/footer";
import Video from "../components/video";
import {
  FaHeartbeat,
  FaFileMedical,
  FaProcedures,
  FaStarOfLife,
  FaWhatsapp
} from "react-icons/fa";

export default () => {
  const data = useStaticQuery(graphql`
    query {
      imageOne: file(
        relativePath: { eq: "images/autohemoterapia_propiedades.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            aspectRatio
            sizes
            base64
            src
            srcSet
          }
        }
      }
      imageTwo: file(
        relativePath: { eq: "images/autohemoterapia_ventajas.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            aspectRatio
            sizes
            base64
            src
            srcSet
          }
        }
      }
      imageThree: file(relativePath: { eq: "images/autohemoterapia_en_Mexico.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            aspectRatio
            sizes
            base64
            src
            srcSet
          }
        }
      }
      imageFour: file(
        relativePath: { eq: "images/autohemoterapia_beneficios_salud.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            aspectRatio
            sizes
            base64
            src
            srcSet
          }
        }
      }
      imageFive: file(
        relativePath: { eq: "images/que_cura_autohemoterapia.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            aspectRatio
            sizes
            base64
            src
            srcSet
          }
        }
      }
      imageSeven: file(
        relativePath: { eq: "images/que_cura_autohemoterapia.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            aspectRatio
            sizes
            base64
            src
            srcSet
          }
        }
      }
      imageSe: file(
        relativePath: { eq: "images/alerta.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            aspectRatio
            sizes
            base64
            src
            srcSet
          }
        }
      }
      imageSix: file(
        relativePath: { eq: "images/autohemoterapia_en_mexico.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            aspectRatio
            sizes
            base64
            src
            srcSet
          }
        }
      }
    }
  `);
  return (
    <div>
      <Helmet />
      

      <a
        href="https://api.whatsapp.com/send?phone=5215539994763&text=Hola%21%20Quisiera%20m%C3%A1s%20informaci%C3%B3n%20sobre%20la%20vacuna%20de%20Autohemoterapia.%20"
        className="float"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FaWhatsapp size={45} className="my-float" />
      </a>
      <section className="hero is-fullheight atlas-1">
      <Navbar />
        <div className="hero-body">
          <div className="container">
            <div className="columns has-text-centered">
              <div className="column"></div>
              <div className="column is-6">
                <h1 className="subtitle is-2 is-size-5-mobile has-text-white has-text-weight-bold is-uppercase">
                  Autohemoterapia: beneficios, qué es y cómo funciona
                </h1>
                <p className="subtitle is-5 is-size-6-mobile has-text-white">
                  Activa tu{" "}
                  <span className="has-text-white has-text-weight-bold">
                    Sistema Inmunológico
                  </span>{" "}
                  y combate enfermedades como diabetes, alergias, acné, asma,
                  artritis y lupus, entre otros.
                </p>
              </div>

              <div className="column"></div>
            </div>
            <div className="columns has-text-centered  has-text-centered-mobile">
              <div className="column"></div>
              <div className="column">
                <a
                  href="/contacto"
                  className="button is-danger is-inverted is-outlined is-rounded"
                >
                  AGENDA UNA CITA
                </a>
              </div>
              <div className="column">
                <a
                  href="/tratamiento-a-distancia"
                  className="button is-danger is-inverted is-outlined is-rounded"
                >
                  TRATAMIENTO A DISTANCIA
                </a>
              </div>
              <div className="column">
                <a
                  href="#informacion"
                  className="button is-danger is-inverted is-outlined is-rounded"
                >
                  LEER MÁS
                </a>
              </div>
              <div className="column"></div>
            </div>
            <div className="columns">
              <div className="column">
                <p></p>
              </div>
              <div className="column is-3">
                <Img
                  fluid={data.imageThree.childImageSharp.fluid}
                  alt="Autohemoterapia en México"
                />
              </div>
              <div className="column"></div>
            </div>
          </div>
        </div>
        <div className="hero-foot"></div>
      </section>
      
      <section className="hero is-medium gd-Azul" id="informacion">
        <div className="hero-body">
          <div className="container">
            <div className="columns has-text-centered">
              <div className="column"></div>
              <div className="column is-three-quarters">
                <h2 className="title is-2 is-size-5-mobile has-text-weight-bold is-uppercase">
                  ¿Información sobre<br />autohemoterapia<br />en México?
                </h2>
                <p className="subtitle is-5 is-size-6-mobile">
                  <span className="magical has-text-weight-bold is-uppercase">
                    Hemovacuna®
                  </span>{" "}
                  es tu mejor opción para tratamientos relacionados con la
                  autohemoterapia. No dudes en contactarnos si necesitas más
                  información o la aplicación de esta vacuna que podrá ayudarte
                  a prevenir muchas enfermedades o a combatirlas si ya las
                  padeces.
                </p>
                <p className="subtitle is-5 is-size-6-mobile">
                  <span className="magical has-text-weight-bold is-uppercase">
                    ¿PREFIERES QUE TE ENVIEMOS TU TRATAMIENTO A CASA?
                  </span>{" "}
                  Ya sea porque no vives en la Ciudad de México o porque prefieres quedarte en casa en estas épocas de COVID-19, tenemos la opción de que recibas tu tratamiento hasta la puerta de tu casa y no haya ninguna limitación para que empieces a mejorar tu salud ahora mismo. 
                </p>
              </div>
              <div className="column"></div>
            </div>
            <div className="columns has-text-centered">
              <div className="column"></div>
              <div className="column is-4 padding">
                <a
                  href="/ciudad-de-mexico"
                  className="button is-info is-outlined is-rounded"
                >
                  CDMX
                </a>
                <a
                  href="/tratamiento-a-distancia"
                  className="button is-info is-outlined is-rounded"
                >
                  TRATAMIENTO A DISTANCIA
                </a>
              </div>
              <div className="column"></div>
            </div>
            <div className="columns">
              <div className="column">
                <div className="notification shake is-Amarilloso has-text-white">
                  <FaStarOfLife size={70} />
                  <p className="title is-4 is-size-5-mobile  is-uppercase has-text-white">
                    Autohemoterapia <br />
                    ¿Qué es?
                  </p>
                  <p className="has-text-white">
                    La autohemoterapia es una técnica 100% segura donde tu
                    propio cuerpo es tu principal aliado para combatir diversas
                    enfermedades y prevenirlas.
                  </p>
                  <p className="pa">
                    <a
                      href="#que-es-la-autohemoterapia"
                      className="button is-danger is-inverted is-outlined is-rounded"
                    >
                      Leer más
                    </a>
                  </p>
                </div>
              </div>
              <div className="column">
                <div className="notification shake is-Gris has-text-white">
                  <FaHeartbeat size={70} />
                  <p className="title is-4 is-uppercase has-text-white">
                    Propiedades
                  </p>
                  <p className="has-text-white">
                    La autohemoterapia es una manera novedosa y natural de
                    activar tu sistema inmunológico. Por lo tanto, tu cuerpo
                    está con las defensas despiertas y alertadas todo el tiempo.
                  </p>
                  <p className="pa">
                    <a
                      href="#propiedades-de-la-autohemoterapia"
                      className="button is-danger is-inverted is-outlined is-rounded"
                    >
                      Leer más
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <div className="columns">
              <div className="column">
                <div className="notification shake is-Rojo has-text-white">
                  <FaFileMedical size={70} />
                  <p className="title is-4 is-uppercase has-text-white">
                    Beneficios
                  </p>
                  <p className="has-text-white-ter">
                    Tener un buen sistema inmunológico ya es un gran beneficio.
                    La autohemoterapia además es 100% natural, por lo que tu
                    cuerpo no recibe químicos o drogas con efectos secundarios.
                  </p>
                  <p className="pa">
                    <a
                      href="#autohemoterapia-beneficios-en-la-salud"
                      className="button is-danger is-inverted is-outlined is-rounded"
                    >
                      Leer más
                    </a>
                  </p>
                </div>
              </div>
              <div className="column">
                <div className="notification shake is-Negro has-text-white">
                  <FaProcedures size={70} />
                  <p className="title is-4 is-uppercase has-text-white">
                    Ventajas y desventajas
                  </p>
                  <p className="has-text-grey-lighter">
                    La principal ventaja es que una mejora a tu sistema
                    inmunológico es una mejora a muchos malestares de tu cuerpo.
                    Por lo tanto, con un solo tratamiento combates varias cosas.
                  </p>
                  <p className="pa">
                    <a
                      href="#autohemoterapia-ventajas-desventajas"
                      className="button is-danger is-inverted is-outlined is-rounded"
                    >
                      Leer más
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <div className="columns">
              <div className="column"></div>
              <div className="column">
              <Video
              videoSrcURL="https://www.youtube.com/embed/6WCHHNBC6AA"
              videoTitle="Autohemoterapia en México"
            />
                            <Img
                  fluid={data.imageSe.childImageSharp.fluid}
                  alt="Alerta"
                />
              </div>
              <div className="column"></div>
            </div>

          </div>
        </div>


        
      </section>

      <section
        className="hero is-medium gd-Azul"
        id="que-es-la-autohemoterapia"
      >
        <div className="hero-body">
          <div className="container">
            <div className="columns has-text-centered">
              <div className="column"></div>
              <div className="column is-three-quarters">
                <h2 className="subtitle is-2 is-size-5-mobile has-text-weight-bold is-uppercase">
                  Autohemoterapia,<br />¿qué es?
                </h2>
                <p className="subtitle is-5 is-size-6-mobile">
                  A pesar de que surgió como un tratamiento enfocado a la piel,
                  conforme ha ido pasado el tiempo la <a href="https://es.wikipedia.org/wiki/Autohemoterapia">autohemoterapia</a> finalmente
                  ha destacado por su capacidad para ayudar a combatir
                  exitosamente enfermedades autoinmunes y a subir las defensas
                  del sistema inmunológico.{" "}
                </p>
                <p className="subtitle is-5 is-size-6-mobile">
                  Como dijimos anteriormente, la autohemoterapia consiste en
                  inyectar la propia sangre del paciente para usar sus
                  anticuerpos como antígenos y así estimular a su sistema inmune
                  para que produzca anticuerpos normales y combata los
                  autoanticuerpos.
                </p>
                <p className="subtitle is-5 is-size-6-mobile">
                  El doctor Jorge González es el pionero en México del estudio
                  de la autohemoterapia. En la década de los ochenta perfeccionó
                  una vacuna en donde separa los autoanticuerpos de los glóbulos
                  rojos y los somete a un choque térmico para convertirlos en
                  antígeno. Ese es el origen de la autohemoterapia
                  contemporánea.{" "}
                </p>
                <p className="subtitle is-5 is-size-6-mobile">
                  El doctor González publicó 41 trabajos científicos y produjo
                  11 películas relacionadas con la biomedicina. Dio clases,
                  cursos y seminarios en universidades de Texas, en la Sorbonne
                  (Francia), en Escocia, Cuba, Costa Rica, China, entre muchos
                  otros.{" "}
                </p>
                <p className="padding-top">
                  <Img
                    fluid={data.imageSeven.childImageSharp.fluid}
                    alt="¿Qué cura la autohemoterapia?"
                  />
                </p>
              </div>
              <div className="column"></div>
            </div>
          </div>
        </div>
      </section>

      <section
        className="hero is-medium gd-Azul"
        id="propiedades-de-la-autohemoterapia"
      >
        <div className="hero-body">
          <div className="container">
            <div className="columns has-text-centered">
              <div className="column"></div>
              <div className="column is-three-quarters">
                <h2 className="subtitle is-2 is-size-5-mobile has-text-weight-bold is-uppercase">
                  Propiedades de la autohemoterapia{" "}
                </h2>
                <p className="subtitle is-5 is-size-6-mobile">
                  Cuando al cuerpo humano entra un agente desconocido, todas las
                  defensas se activan. Piensa en la siguiente analogía: tu
                  cuerpo es un edificio y llega una persona desconocida. Lo
                  lógico es que la gente encargada de seguridad entre en acción
                  para identificar de qué se trata.{" "}
                </p>
                <p className="subtitle is-5 is-size-6-mobile">
                  En tu cuerpo pasa lo mismo. Si algo entra, entonces tus
                  defensas se activan para corroborar de que se trata de algo
                  bueno (o combatirlo a tiempo si se trata de algo malo).{" "}
                </p>
                <p className="subtitle is-5 is-size-6-mobile">
                  Entendiendo esto, el sistema inmunológico (las defensas del
                  cuerpo) se puede activar de manera controlada. La
                  autohemoterapia justamente busca esto.{" "}
                </p>
                <p className="subtitle is-5 is-size-6-mobile">
                  De esta manera, tu sangre se convierte en tu aliada. Es
                  retirada de tu cuerpo, procesada y luego regresa a ti para
                  activar tus defensas.{" "}
                </p>
                <p className="subtitle is-5 is-size-6-mobile">
                  Como es tu sangre y es tratada científicamente,{" "}
                  <strong>no te hace ningún daño</strong>. Pero tiene ese efecto
                  colateral muy positivo: tus defensas se han despertado para
                  averiguar de qué se trata. De esta forma, tu cuerpo tiene las
                  defensas muy activas y sirve para combatir otros agentes que
                  sí te están causando un problema o evitar que llegue alguna
                  enfermedad.
                </p>
                <p className="padding-top">
                  <Img
                    fluid={data.imageOne.childImageSharp.fluid}
                    alt="Propiedades de la autohemoterapia"
                  />
                </p>
              </div>
              <div className="column"></div>
            </div>
          </div>
        </div>
      </section>

      <section
        className="hero is-medium gd-Azul"
        id="autohemoterapia-beneficios-en-la-salud"
      >
        <div className="hero-body">
          <div className="container">
            <div className="columns has-text-centered">
              <div className="column"></div>
              <div className="column is-three-quarters">
                <h2 className="subtitle is-2 is-size-5-mobile has-text-weight-bold is-uppercase">
                  Autohemoterapia: beneficios en la salud
                </h2>
                <p className="subtitle is-5 is-size-6-mobile">
                  ¿A quién no le gustaría tener las defensas siempre en grandes
                  condiciones? Que su sistema inmunológico siempre estuviera al
                  máximo de sus capacidades para prevenir enfermedades o para
                  hacer frente a males que llevan años bajando su calidad de
                  vida.{" "}
                </p>
                <p className="subtitle is-5 is-size-6-mobile">
                  La autohemoterapia permite ganar mucho y no perder nada. No
                  estás metiendo químicos a tu cuerpo que pueden arreglar una
                  cosa, pero, a la larga, descomponer otra o generar alguna
                  adicción.{" "}
                </p>
                <p className="subtitle is-5 is-size-6-mobile">
                  La autohemoterapia es convertir a tu propio cuerpo en tu mejor
                  aliado y ayudarlo a que combata a sus malestares. Por lo
                  tanto, es un método 100% natural que sirve para prevenir y
                  para superar enfermedades.{" "}
                </p>
                <p className="subtitle is-5 is-size-6-mobile">
                  Por eso mismo, es muy útil como tratamiento auxiliar para
                  enfermedades como la diabates, alergias, artritis, la anemia,
                  la depresión, la dermatitis, la esclerosis múltiple, el
                  parkinson, la miastenia, entre otras.{" "}
                </p>

                <p className="padding-top">
                  <Img
                    fluid={data.imageFour.childImageSharp.fluid}
                    alt="Beneficios de la autohemoterapia"
                  />
                </p>
              </div>
              <div className="column"></div>
            </div>
          </div>
        </div>
      </section>

      <section
        className="hero is-medium gd-Azul"
        id="autohemoterapia-ventajas-desventajas"
      >
        <div className="hero-body">
          <div className="container">
            <div className="columns has-text-centered">
              <div className="column"></div>
              <div className="column is-three-quarters">
                <h2 className="subtitle is-2 is-size-5-mobile has-text-weight-bold is-uppercase">
                  Autohemoterapia, ventajas y desventajas
                </h2>
                <p className="subtitle is-5 is-size-6-mobile">
                  La <strong>principal ventaja de la autohemoterapia</strong>{" "}
                  , al igual que el  <a href="https://factor-transferencia.mx"> Factor de Transferencia</a>
                
, frente a otras terapias médicas y alternativas es que no
                  requiere el uso de ninguna droga.{" "}
                </p>
                <p className="subtitle is-5 is-size-6-mobile">
                  No es necesario inhibir el sistema inmunológico con ninguna
                  medicina porque la autohemoterapia se basa en estimularlo para
                  lograr el objetivo de detener el proceso crónico-degenerativo
                  de las enfermedades.{" "}
                </p>
                <p className="subtitle is-5 is-size-6-mobile">
                  De esta manera, se logra que el paciente no tenga ningún
                  efecto colateral durante todo el tratamiento.{" "}
                </p>
                <p className="subtitle is-5 is-size-6-mobile">
                  Además, la autohemoterapia sirve para hacer frente a
                  diferentes enfermedades, pero también para prevenirlas. De
                  esta forma, con un tratamiento adecuado, puedes ayudar a tu
                  cuerpo a estar listo para combatir muchas enfermedades que
                  padecen millones de seres humanos.
                </p>
                <p className="subtitle is-5 is-size-6-mobile">
                  La <strong>principal desventaja de la autohemoterapia</strong>{" "}
                  que tiene es que es un tratamiento de larga duración. Si bien
                  los resultados se empiezan a dar de inmediato, es necesaria la
                  constancia y el compromiso del paciente para tener su sistema
                  inmunológico en estado óptimo.
                </p>
                <p className="padding-top">
                  <Img
                    fluid={data.imageTwo.childImageSharp.fluid}
                    alt="Ventajas de la autohemoterapia"
                  />
                </p>
              </div>
              <div className="column"></div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};
